export const typeOperation = [
	{
		value: 'IN',
		label: 'Entrada',
	},
	{
		value: 'OUT',
		label: 'Salida',
	},
];
