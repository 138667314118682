export const parentesco = [
    {
        value: 'Primo',
        label: 'Primo',
    },
    {
        value: 'Hermano',
        label: 'Hermano',
    },
    {
        value: 'Hijo',
        label: 'Hijo',
    },
    {
        value: 'Padre',
        label: 'Padre',
    },
    {
        value: 'Tío',
        label: 'Tío',
    },
    {
        value: 'Amigo',
        label: 'Amigo',
    }
]