export const statusSlipReservation = [
	// {
	//   value: "PENDING",
	//   label: "Pendiente",
	// },
	{
		value: 'CONFIRMED',
		label: 'Confirmada',
	},
];
