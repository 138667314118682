const ROLE_ADMINISTRACION = 'ROLE_ADMINISTRACION';
const ROLE_COMPANIA = 'ROLE_COMPANIA';
const ROLE_OPERACIONES = 'ROLE_OPERACIONES';
const ROLE_CONTABILIDAD = 'ROLE_CONTABILIDAD';
const ROLE_MUELLE = 'ROLE_MUELLE';
const ROLE_CAJA = 'ROLE_CAJA';
const ROLE_TOUR = 'ROLE_TOUR';

export {
	ROLE_ADMINISTRACION,
	ROLE_COMPANIA,
	ROLE_OPERACIONES,
	ROLE_CONTABILIDAD,
	ROLE_MUELLE,
	ROLE_CAJA,
	ROLE_TOUR,
};
